import React, { useState, useEffect } from "react";
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {
  Alert,
  Box,
  Container,
  Divider,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Stack,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { colors } from "../lib/theme";
import { auth } from "../../shared/firebase";
import { useAuth } from "../../shared/useAuth";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MemoryIcon from "@mui/icons-material/Memory";
import { Helmet } from "react-helmet";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../../shared/firebase";
import ContainerBox from "../../shared/ContainerBox";
import GoogleIcon from '@mui/icons-material/Google';

const GetTheApp = () => (
  <Container maxWidth="sm">
    <Box>
      <Paper
        elevation={3}
        sx={{
          padding: "15px 30px",
          background: `linear-gradient(45deg, ${colors.gold} 30%, ${colors.white} 90%)`,
          borderRadius: 2,
          boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
        }}
      >
        <Stack spacing={3} alignItems="center">
          <PhoneIphoneIcon sx={{ fontSize: 60, color: colors.navy }} />
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            align="center"
            sx={{ color: colors.navy, fontWeight: "bold" }}
          >
            iOS App Available Now!
          </Typography>
          <Typography
            variant="h6"
            align="center"
            sx={{ color: colors.navy }}
          >
            If you prefer, you can use the iOS version of Golden Record.
          </Typography>
          <Button
            variant="contained"
            size="large"
            startIcon={<MemoryIcon />}
            href="https://apps.apple.com/us/app/golden-record/id6501951254"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              bgcolor: "#8B6B00",
              color: colors.white,
              fontWeight: "bold",
              textTransform: "none",
              fontSize: "1.1rem",
              padding: "12px 24px",
              letterSpacing: "1px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                bgcolor: "#6B5200",
                color: colors.white,
                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            Install the app
          </Button>
          <Typography
            variant="body2"
            align="center"
            sx={{ color: colors.navy, mt: 2 }}
          >
            Start preserving your precious memories today!
          </Typography>
        </Stack>
      </Paper>
    </Box>
  </Container>
);

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const navigate = useNavigate();

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }

    const urlParams = new URLSearchParams(window.location.search);
    const invitedToParam = urlParams.get('invitedTo');
    if (invitedToParam) {
      localStorage.setItem('invitedTo', invitedToParam);
    }
  }, [user, navigate]);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!agreeToTerms) {
      setError("You must agree to the Privacy Policy and Terms of Service to register.");
      return;
    }
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      const lowercaseEmail = email.toLowerCase();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        lowercaseEmail,
        password
      );

      // Add user to Firestore only if they don't already exist
      const user = userCredential.user;
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          email: lowercaseEmail,
          subscriptionLevel: 0,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }

      // Send email verification
      await sendEmailVerification(user);

      localStorage.setItem("user", JSON.stringify({...user, email: lowercaseEmail}));
      // Navigate to the verify email page with the user's email as a parameter
      navigate(`/verify-email?email=${encodeURIComponent(lowercaseEmail)}`);
    } catch (err) {
      console.error("Registration error:", err);
      if (err.code === "auth/email-already-in-use") {
        setError(<>This email is already registered. Please use a different email or <Link href="/login" sx={{ color: colors.black }}>log in here</Link>.</>);
      } else if (err.code === "auth/weak-password") {
        setError("Password is too weak. Please use a stronger password.");
      } else {
        setError("An error occurred during registration. Please try again later.");
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleRegister(event);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Add user to Firestore only if they don't already exist
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          email: user.email.toLowerCase(),
          subscriptionLevel: 0,
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      }

      localStorage.setItem("user", JSON.stringify({...user, email: user.email.toLowerCase()}));
      navigate("/dashboard");
    } catch (err) {
      console.error("Google sign up error:", err);
      setError("An error occurred during Google sign up. Please try again later.");
    }
  };

  return (
    <Box>
      <Helmet>
        <title>Register - Golden Record</title>
        <meta name="description" content="Register for a Golden Record account" />
      </Helmet>
      <Typography variant="h3" sx={{ color: colors.gold, mb: 0 }}>
        Almost there! Create your Golden Record account
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: colors.white, pt: 1, fontSize: "1.3rem" }}
      >
        Enter your email and password to register for an account.
        {" "}
        <Link href="/login" sx={{ color: colors.gold }}>
          Already have an account? Log in here.
        </Link>
      </Typography>

      <ContainerBox>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>
                  Register
                </Typography>
                <form onSubmit={handleRegister}>
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyPress={handleKeyPress}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    required
                  />
                  <TextField
                    fullWidth
                    label="Confirm Password"
                    type="password"
                    variant="outlined"
                    margin="normal"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    required
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={agreeToTerms}
                        onChange={(e) => setAgreeToTerms(e.target.checked)}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        I agree to the{" "}
                        <Link href="/privacy-policy" target="_blank" rel="noopener">
                          Privacy Policy
                        </Link>{" "}
                        and{" "}
                        <Link href="/terms-of-service" target="_blank" rel="noopener">
                          Terms of Service
                        </Link>
                      </Typography>
                    }
                  />
                  {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                      {error}
                    </Alert>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 2,
                      width: { xs: '100%', sm: 'auto' }
                    }}
                    disabled={!agreeToTerms}
                  >
                    Register
                  </Button>
                </form>
                <Divider sx={{ my: 2 }}>OR</Divider>
                <Button
                  fullWidth
                  variant="outlined"
                  startIcon={<GoogleIcon />}
                  onClick={handleGoogleSignUp}
                  sx={{ mt: 2 }}
                >
                  Sign up with Google
                </Button>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <GetTheApp />
            </Grid>
          </Grid>
        </Container>
      </ContainerBox>
    </Box>
  );
};

export default Register;
